<template>
<tbody class="table-font" style="font-size:13px;" v-if="item!=null">
    <tr>
        <td>{{index+1}}</td>
        <td>{{ item.customer?.generatedid}}</td>
        <td><span v-if="item.customer!=null">{{item.customer.name}}</span></td>
        <td><display-mobiles :mobiles="item.customer?.mobiles"></display-mobiles></td>
        <td><display-first-address :addresses="item.customer?.addressess"></display-first-address></td> 
        <td>{{item.complaintno}}</td> 
        <td style="font-size:10px ;">
            <div>
                <div>
                    <span v-if="item.product!=null && item.product.category!=null">{{item.product.category.name}}</span>
                    <span v-if="item.product!=null && item.product.attribute!=null">
                        <div v-for="company in item.product.attribute" :key="company.id">
                        {{ getcompany(company.attribute_value_ids) }}
                        </div>
                    </span>
                    <span >{{item.product?.name}}</span>&nbsp;
                </div>
            </div>
        </td>
        <td>{{item.remark}}</td>
        <td><span v-if="item.followby!=null">{{item.followby.name}}</span></td>
        <td> <span v-if="item.disposeby!=null">{{ item.disposeby.name }}<br>{{ item.disposedate}}</span></td>
        <!-- <td><button class="badge btn-prime btn-xs" @click="showchats()">conversation</button></td> -->
        <td><button class="badge btn-primary-delete btn-xs" @click="enabledisposeoff()">Conversation</button></td>
    </tr>
    <tr v-if="visitcomments">
        <td colspan="11">
            <customer-conversation :item="item" :show="false"></customer-conversation>
        </td>
    </tr>
</tbody>
</template>
<script>
import moment from 'moment'
import CustomerConversation from '../complaint/CustomerConversation.vue';
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import Constants from '../../components/utilities/Constants.vue';
import { mapGetters } from 'vuex'
export default {
    mixins:[Constants],
    props:['item','index'],
    components:{CustomerConversation,DisplayFirstAddress,DisplayMobiles},
    data() {
        return {
            moment:moment,
            visitcomments:false
        }
    },
    computed:{
        ...mapGetters(['employees','loggedinuser','attributes'])
    },
    methods: {
        showchats(){
            this.visitcomments=!this.visitcomments
        },
        requestmethod(item){
            let type="";
            switch (item.requesttype) {
                case 1:
                    type= "QUERY";
                    break;
                case 2:
                    type= "Complaint";
                    break;
                case 3:
                    type= "Feedback";
                    break;
                case 4:
                    type= "ORDER";
                    break;
                default:
                    break;
            }
            return type;
        },
        leadbymethod(item){
            let type="";
            switch (item.leadtype) {
                case 1:
                    type= "INCOMING CALL";
                    break;
                case 2:
                    type= "OUTGOING CALL";
                    break;
                case 3:
                    type= "NEWS PAPER ADD";
                    break;
                case 4:
                    type= "SHOW ROOM WALKIN";
                    break;
                case 5:
                    type= "HOME VISIT";
                    break;
                case 6:
                    type= "REFFERRED BY OTHER";
                    break;
                case 7:
                    type= "INTERNET";
                    break;
                case 8:
                    type= "BY ADVISOR";
                    break;
                default:
                    break;
            }
            return type;
        },
        refresh(){
            this.$parent.refresh()
        },
        enabledisposeoff(){
            this.visitcomments=!this.visitcomments
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                    (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                    return companyname.value;
                }
            }
            return attribute_value_ids;
        },
    },
}
</script>